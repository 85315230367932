import React from 'react';
import 'pages/intro/Intro.scss'
import EntireFlow from 'assets/images/icons/pharmacyPortal2.png';
import pharmacyLogo from 'assets/images/icons/pharmacy_1_48x48.png';
import {routes} from 'constants/routes';
import {Container, Nav, Navbar, NavbarBrand} from 'react-bootstrap'

/**
 * Stateless component responsible for rendering a simple SSI introduction screen.
 * */
const IntroPage = () => {
  return (
    <div className='intro page-form page-form--large'>
      <div className='intro__heading-block'>
      <img src={pharmacyLogo} className='logo-pharmacy' alt='logo-pharm'/>
      <br/>
        <h1 className='intro__heading'>
          Pharmacy Portal
        </h1>
        <h5 className='intro__subheading'>Patient Health Data Distributed Interface</h5>
      </div>
      <div className='intro__text-block'>
        <h4>Scenario</h4>
        <p>After seeing the doctor, the patient visits the pharmacy to get the prescription filled by the pharmacist.</p>
        <h4>Roles in this scenario</h4>
        <p>There are 3 roles in SSI cycle: <strong>ISSUER</strong>, <strong>ENDORSER</strong>, and <strong>VERIFIER</strong>. Each of them is explained in the example below.</p>
      </div>
      <div className='intro__example'>
        <img className='flow-size' src={EntireFlow} alt='entire-flow'/>
      </div>
      <div className='intro__roles-description'>
        <div className='intro__roles-description-role'>
          <h3>Issuer</h3>
          <p>Patient gives a prescription to the Pharmacist.  
            <br/>
            Pharmacist issues a digital verified credential for the precription fulfilment.</p>
        </div>
        <div className='intro__roles-description-role'>
          <h3>Endorser</h3>
          <p>Pharmacist explains to the patient about the prescribed medication and hands over the medicines to the patient.  The patient endorses the digital verifiable credential to attest that the prescription has been fulfilled as required.</p>
        </div>
        <div className='intro__roles-description-role'>
          <h3>Verifier</h3>
          <p>Patient shares the endorsed digital verifiable credential with the Insurer.  The insurer is satisfied that the transaction has taken place and initiates reimbursement payment to the pharmacy for the dispensing fee, cost of the dispensed medicines, and other associated charges.</p>
        </div>
      </div>

      <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>

    </div>
  )
}

export default IntroPage
