import React from 'react';

/**
 * Very simple 404 page.
 * */
const NotFoundPage = () => {
  return <div>404</div>
}

export default NotFoundPage;
